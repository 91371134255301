.c-layout {
  &--logged {
    padding: 20px 16px 200px 16px;
    position: relative;
    min-height: 100vh;
  }
}
.l-mobile-flex {
  display: flex;
}

.c-loader--full-page {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 768px) {
  .c-layout {
    &--logged {
    }
  }
  .l-tablet-grid {
    display: grid;
  }
  .l-tablet-flex {
    display: flex;
  }
}

@media screen and (max-width: 1023px) {
  .c-layout {
    &--logged {
      overflow: hidden;
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-layout {
    &--logged {
      padding: 119px 32px 116px 246px;
    }
  }
  .l-desktop-flex {
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .l-desktop-medium-flex {
    display: flex;
  }
  .c-layout {
    &--logged {
      padding-bottom: 96px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .l-desktop-big-flex {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .c-subheader--prospect {
    .c-new-prospect {
      margin-left: 0;
      margin-top: 16px;
    }
  }
}
@media screen and (max-width: 1023px) {
  .c-subheader--prospect {
    .c-subheader__content {
      margin-top: 16px;
    }
    .c-new-prospect {
      margin-left: auto;
    }
  }
}

.Toastify__toast {
  background-color: transparent !important;
  box-shadow: none !important;
}
.Toastify__toast-container {
  padding: 0 !important;
  width: 80vw !important;
}
.Toastify__toast-container--bottom-right {
  bottom: 55px !important;
}
.Toastify__toast {
  padding: 0 !important;
  min-height: 0 !important;
}
.Toastify__toast-body {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
}

.k-notification {
  padding: 0 !important;
  box-shadow: 59px 4px 59px rgba(11, 10, 10, 0.2);
}

.k-notification-container {
  margin: 0 !important;
  display: block !important;
  width: 100%;
}

.k-notification-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 16px;
  font-size: 16px;

  img {
    display: block;
    position: absolute;
    left: 16px;
    top: 16px;
  }
  span {
    display: block;
    margin-left: 8px;
    text-align: center;
  }
}

.k-notification-success {
  border-color: #d8f6d4 !important;
  color: #2a2a2a !important;
  background-color: #d8f6d4 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 0 auto;
  .k-notification-content {
    padding-left: 48px;
    font-size: 14px;
    padding-top: 13px;
    padding-bottom: 13px;
    img {
      top: 12px;
    }
  }
}

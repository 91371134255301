.c-order-advice {
  color: #ed1c24;
  strong {
    margin-right: 4px;
    display: inline-block;
    .k-icon {
      margin-right: 4px;
    }
  }
}

.c-new-order-modal {
  .k-tabstrip-items {
    overflow: visible;

    .k-item {
      font-size: 18px;
      font-weight: 400;
      flex: 1;
      border: 1px solid #2a2a2a;
    }
    .k-link {
      justify-content: center;
    }
  }
  .k-tabstrip > .k-content,
  .k-tabstrip-items-wrapper {
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
  .k-tabstrip > .k-content {
    margin-left: 0;
    margin-right: 0;
  }
  .k-tabstrip-items .k-item.k-active {
    border-color: #610d11;
  }
  &__button {
    width: 100%;
    .k-dropdownlist + & {
      margin-top: 24px;
    }
  }
  .c-form-field + .c-form-field {
    margin-top: 16px;
  }
}
.c-existing-orders {
  font-size: 13px;
  border: 1px solid var(--primary, #610d11);
  padding: 16px;
  max-height: 52vh;
  overflow: auto;
  &__item {
    display: flex;
    align-items: center;
    padding-block: 8px;
    border-bottom: 1px solid #d3d3d3;
  }
  &__meta {
    color: #2a2a2a;
    display: flex;
    align-items: center;
    gap: 12px;
    @media screen and (max-width: 767px) {
      gap: 0;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__id {
    font-size: 15px;
    strong {
      margin-left: 4px;
    }
  }
  &__amount {
    strong {
      margin-left: 4px;
    }
  }
  &__date {
    color: #757575;
    span {
      margin-left: 4px;
    }
  }
  &__button {
    padding-block: 4px !important;
    font-size: 12px !important;
    height: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .c-new-order-modal {
    .k-tabstrip-items {
      .k-item {
        font-size: 18px;
      }
    }
  }
}

.c-subheader {
  &--listing-orders {
    .c-subheader {
      &__title {
        margin-bottom: 8px !important;
      }
      &__button {
        &--no-filter {
          font-weight: 400 !important;
          letter-spacing: 0 !important;
          font-size: 14px !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          text-transform: none !important;
          .k-button-text {
            color: #000 !important;
          }
          .k-icon {
            color: #610d11 !important;
          }
        }
      }
    }
    .c-form-field.k-pos-relative {
      margin-top: 0;
    }
    .c-tags {
      margin-top: 16px;
      flex-wrap: wrap;
    }
  }
}

.c-icon-filter {

  border: none !important;
  text-transform: none !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-family: "Canela";
  font-weight: 300 !important;

  &.c-subheader__button--selected {
    //color: white !important;
    background-size: 65%;
  }
  &.c-subheader__button--selected.c-background-square {
    &__1 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_square-01.svg');
    }
    &__2 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_square-02.svg');
    }
    &__3 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_square-03.svg');
    }
  }

  &.c-subheader__button--selected.c-background-triangle {
    &__1 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle-01.svg');
    }
    &__2 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle-02.svg');
    }
    &__3 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle-03.svg');
    }
  }

  &:hover {
    background-color: transparent !important;
    border: none !important;
  }
  &.c-background-square {
    &__1:hover {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_square_white-01.svg');
    }
    &__2:hover {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_square_white-02.svg');
    }
    &__3:hover {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_square_white-03.svg');
    }
  }
  &.c-background-triangle {
    &__1:hover {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle_white-01.svg');
    }
    &__2:hover {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle_white-02.svg');
    }
    &__3:hover {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle_white-03.svg');
    }
  }

  color: #610d11 !important;
  .k-i-close {
    padding-top: 4px;
    width: 0.4em;
  }
  .k-icon {
    font-size: 13px;
  }
}

.c-icon-filter {
  width:  43px !important;
  height: 43px !important;
  background-color: transparent !important;
  background-size: 65%;
  background-position: center;
  background-repeat: no-repeat;
}
.c-icon-filter .k-button-text {
  padding-top: 6px;
  font-size: 13px;
  background: transparent !important;
  background-color: transparent !important;
}
.c-background-square {

  &__1 {
    background-image: url('/assets/icons/numbered_shapes_icon/DRV_square-01.svg');
  }
  &__2 {
    background-image: url('/assets/icons/numbered_shapes_icon/DRV_square-02.svg');
  }
  &__3 {
    background-image: url('/assets/icons/numbered_shapes_icon/DRV_square-03.svg');
  }
}
.c-background-triangle {
  &__1 {
    background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle-01.svg');
  }
  &__2 {
    background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle-02.svg');
  }
  &__3 {
    background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle-03.svg');
  }
}

@media screen and (max-width: 767px) {
  .c-subheader {
    &--listing-orders {
      .c-subheader {
        &__title {
          margin-bottom: 0 !important;
        }
      }
      .c-subheader__filter {
        flex-direction: column;
        align-items: start;
      }
      .c-form-field.k-pos-relative {
        order: 1;
        margin-top: 16px;
      }
      .c-filter {
        order: 2;
      }
      .c-form-field--search {
        order: 3;
      }
    }
  }
}

@media screen and (min-width: 1220px) {
  .c-subheader {
    &--listing-orders {
      .c-subheader {
        &__title {
          margin-bottom: 0 !important;
        }
        &__main {
          display: flex;
        }
      }
    }
  }
}

@media screen and (min-width: 1220px) and (max-width: 1300px) {
  .c-subheader {
    &--listing-orders {
      .c-form-field--search {
        margin-left: 16px;
        min-width: 280px;
      }
    }
  }
}

.c-order-total {
  &__content {
    font-size: 14px;
    color: #2a2a2a;
    padding-right: 20px;
    &:nth-of-type(even) {
      background-color: #f9f6f3;
    }
    &--empty {
      color: #b7b7b7;
    }
    &--full {
      font-weight: 700;
    }
  }
}

.c-order-advice {
  color: #ed1c24;
  strong {
    margin-right: 4px;
    display: inline-block;
    .k-icon {
      margin-right: 4px;
    }
  }
}

.c-new-order-modal {
  .k-tabstrip-items {
    overflow: visible;

    .k-item {
      font-size: 18px;
      font-weight: 400;
      flex: 1;
      border: 1px solid #2a2a2a;
    }
    .k-link {
      justify-content: center;
    }
  }
  .k-tabstrip > .k-content,
  .k-tabstrip-items-wrapper {
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
  .k-tabstrip > .k-content {
    margin-left: 0;
    margin-right: 0;
  }
  .k-tabstrip-items .k-item.k-active {
    border-color: #610d11;
  }
  &__button {
    width: 100%;
  }
  .c-form-field + .c-form-field {
    margin-top: 16px;
  }
}

.c-modal-testata,
.c-modal-scroll {
   .c-form-field {
    .k-checkbox-label {
      font-weight: normal;
      font-size: 11px !important;
      text-transform: uppercase !important;
      margin-inline-start: 8px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-new-order-modal {
    .k-tabstrip-items {
      .k-item {
        font-size: 18px;
      }
    }
  }
}

.c-order-fragrances {
  &__header {
    margin-top: 8.5px;
    font-size: 13px;
    padding: 6px 12px;
    background-color: #f1e9e0;
    color: #2a2a2a;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    white-space: nowrap;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 100%;
      top: 0;
      bottom: 0;
      background-color: #f9f6f3;
    }
    strong {
      font-weight: 700;
    }
  }
  &__product {
    height: 48px;
    padding-right: 16px;
    figure {
      margin-right: 12px;
      width: 48px;
      height: 44px;
      img {
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        font-family: "object-fit: contain;";
        margin-top: 2px;
        margin-bottom: 2px;
        height: 42px;
      }
    }
    span {
      font-size: 15px;
      //text-transform: lowercase;
      display: block;
      margin-right: 14px;
      &::first-letter {
        //text-transform: uppercase;
      }
    }
    strong {
      font-size: 12px;
      padding: 4px 8px;
      font-weight: 400;
      background-color: #d8e8d4;
      border-radius: 2px;
      display: block;
      & + strong {
        margin-left: 4px;
      }
    }
    &:nth-of-type(even) {
      background-color: #f9f6f3;
    }
  }
}

@media screen and (max-width: 767px) {
  .c-order-fragrances {
    ul {
    }
    &__product {
      span {
        font-size: 13px;
      }
    }
  }
}

.c-company-selected {
  &__client {
    display: inline-block;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1;
  }
}

@media screen and (min-width: 768px) {
  .c-company-selected {
    &__client {
      max-width: 400px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-company-selected {
    font-size: 14px;
    color: #eeeeee;
    line-height: 1;
    margin-bottom: 5px;
    &__client {
      color: #ae888a;
      font-size: 12px;
      max-width: 300px;
    }
  }
}
@media screen and (min-width: 1280px) {
  .c-company-selected {
    &__client {
      max-width: 350px;
    }
  }
}

.k-appbar--footer {
  overflow: visible;
  bottom: -1px !important;
  &.u-disabled {
    display: none;
  }
}
.c-footer {
  background-color: #d8e8d4;
  z-index: 100;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 0;
  width: 100vw;
  margin-top: -13px;
  margin-bottom: -13px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  min-height: 56px;

  &__button-menu {
    position: absolute;
    left: 26px;
    border-radius: 50%;
    border-color: #000000;
    color: #000;
    background-color: transparent !important;
    box-shadow: none !important;
    background-image: none;
    width: 24px;
    height: 24px;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    top: 50%;
    transform: translateY(-50%);
    .k-icon {
      font-size: 13px;
    }
    &:hover {
      background-color: #000 !important;
      color: #fff;
    }
    & + .k-animation-container {
      position: absolute;
      bottom: 100%;
      left: 34px;
      background: #ffffff;
      border-radius: 0;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
      .c-footer__actions {
        display: block !important;
        border-right: none;
        margin-right: 0;
        padding-right: 0;
        padding-top: 8px;
        padding-bottom: 8px;
        .k-button {
          padding: 4px 12px;
        }
      }
    }
  }
  &__selection {
    background-color: #ed1c24;
    &-button {
      height: 24px;
      font-size: 11px;
      color: #fff !important;
      border-color: #ed1c24 !important;
      margin-left: 8px;
      padding-left: 8px !important;
      padding-right: 8px !important;
      &--success {
        color: #2a2a2a !important;
        border-color: #2a2a2a !important;
      }
      &:hover {
        background-color: transparent !important;
      }
    }
    &-button {
      border: none !important;
      text-transform: none !important;
      letter-spacing: 0 !important;
      font-weight: 700 !important;
      text-decoration: underline !important;
    }
    &-alert {
      background-color: #ed1c24;
      border-radius: 2px;
      font-size: 12px;
      color: #fff;
      padding: 2px 8px;
      line-height: 1.54;
      max-width: 164px;

      &--success {
        background-color: #b4d8ac;
        color: #2a2a2a;
      }
    }
    &--success {
      background-color: #b4d8ac;
      color: #2a2a2a;
    }
  }
  &-unaunthicated {
    width: 100vw;
  }
  &--details-order {
    background: #f1e9e0;
    justify-content: flex-end !important;
    .c-percentage {
      background: #dbcdbe;
    }
    //height: 56px;

    .c-footer__selection {
      left: 56px;
    }
    .c-footer__button-menu {
      left: 16px;
      & + .k-animation-container {
        left: 16px;
      }
    }

    .c-footer__order {
      margin-left: 16px;
    }
    .c-footer__title {
      display: none;
    }
  }
  &__total {
    margin-right: 16px;
    line-height: 20px;
    display: flex;
    align-items: baseline;
    strong {
      font-size: 19px;
      line-height: 12px;
      margin-left: 4px;
    }
  }
  &__meta-act {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

@keyframes animation-notification {
  from {
    background-color: #ffc700;
  }
  to {
    background-color: #ffdc00;
  }
}
.c-animation-notification {
  background: #ffdc00;
  border-radius: 24px;
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  height: 32px;
  width: 54px;
  gap: 7px;
  position: relative;

  .k-icon {
    width: 18px;
    height: 18px;
  }
  &--animated {
    animation-name: animation-notification;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .s-state {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}
@media screen and (max-width: 479px) {
  .c-footer {
    &__total {
      span {
        max-width: 40px;
        line-height: 1.1;
      }
    }
    &__sale {
      padding-right: 0;
      border-right: none;
    }
  }
}
@media screen and (max-width: 767px) {
  .c-footer {
    &__button-menu {
      position: relative;
      left: auto !important;
      top: auto;
      transform: none;
      width: 32px;
      height: 32px;
      border-radius: 0;
      margin-bottom: 8px;
    }
    &__layout {
      justify-content: space-between !important;
      .k-button {
        flex: 1;
      }
    }
    &__selection {
      position: relative;
      left: auto !important;
    }

    &__total {
      /*position: absolute;
      right: 16px;
      top: 18px;
      margin-right: 0;*/
      flex: 1;
      justify-content: flex-end;
    }
    &__sale {
      flex: 1;
      margin-right: 0;
    }
    &__content {
      position: relative;
      padding-bottom: 50px;
    }
    &__act {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .c-footer {
    //height: 56px;
  }
  .c-footer__selection {
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .c-footer {
    &__act {
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 1023px) {
  .c-footer {
    &__layout {
      width: 100%;
    }

    &__total {
      margin-right: 0;
      align-items: center;
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-footer {
    &__meta-act {
      margin-right: auto;
      margin-left: 194px;
    }
    &__selection {
      margin-bottom: 8px;

      &-alert {
        min-width: 155px;
      }
    }
    &__layout {
      justify-content: flex-end !important;
    }
    &--details-order {
      background: linear-gradient(
        to right,
        rgba(249, 246, 243, 1) 0%,
        rgba(249, 246, 243, 1) 208px,
        rgba(241, 233, 224, 1) 208px,
        rgba(241, 233, 224, 1) 100%
      );
      justify-content: flex-end !important;
      .c-footer__selection {
        left: 216px;
      }
      .c-footer__button-menu {
        left: 220px;
        & + .k-animation-container {
          left: 220px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .c-animation-notification {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 1279px) {
  .c-footer {
    &__selection {
      width: 100%;
      //text-align: center;
      justify-content: center;
      &-button {
        border: none !important;
        text-transform: none !important;
        letter-spacing: 0 !important;
        font-weight: 700 !important;
        text-decoration: underline !important;
      }
      &--success {
        background-color: #b4d8ac;
      }
    }
    &__selection-alert {
      max-width: 100%;
    }
  }
  .c-footer__actions--inline {
    //display: none !important;
  }
}
@media screen and (min-width: 1280px) {
  .c-footer {
    &__selection {
      margin-bottom: 0;
    }
    &__selection-alert {
      max-width: 155px;
    }
  }
  .c-footer__action-mobile {
    display: none;
  }
}

@media screen and (min-width: 1399px) and (max-width: 1700px) {
  .c-footer__button-label-full {
    display: none;
  }
}

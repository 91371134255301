.c-prospect-state {
  margin-left: 16px !important;
  font-size: 13px !important;
  display: inline-block;
  vertical-align: middle;
  padding: 2px 6px;
  border-radius: 2px;
  background-color: #fafafa;
  font-family: "Arial", sans-serif;

  &.s-da,
  .approvare {
    background-color: #fff9b4;
  }
  &.s-approvato {
    background-color: #d8f6d4;
  }
  &.s-non,
  .approvato {
    background-color: #ffd3d3;
  }
}

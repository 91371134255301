.c-table-prospects {
  table {
    colgroup {
      col {
        &:first-of-type {
          width: 230px;
        }
        &:nth-of-type(2) {
          width: 260px;
        }
        &:nth-of-type(3) {
          width: 160px;
        }
        &:nth-of-type(4) {
          width: 160px;
        }
        &:nth-of-type(5) {
          width: 160px;
        }

        &:last-of-type {
          width: 160px;
        }
      }
    }
    .k-header {
      &:first-of-type {
        .k-cell-inner {
          width: 230px;
        }
      }
      &:nth-of-type(2) {
        .k-cell-inner {
          width: 260px;
        }
      }
      &:nth-of-type(3) {
        .k-cell-inner {
          width: 160px;
        }
      }
      &:nth-of-type(4) {
        .k-cell-inner {
          width: 160px;
        }
      }
      &:nth-of-type(5) {
        .k-cell-inner {
          width: 160px;
        }
      }

      &:last-of-type {
        .k-cell-inner {
          width: 160px;
        }
      }
    }
  }
}

.c-orders-listing {
  table {
    colgroup {
      col {
        &:first-of-type {
          width: 140px;
        }
        &:nth-of-type(2) {
          width: 200px;
        }
        &:nth-of-type(3) {
          width: 130px;
        }
        &:nth-of-type(4) {
          width: 130px;
        }
        &:nth-of-type(5) {
          width: 115px;
        }
        &:nth-of-type(6) {
          width: 170px;
        }
        &:last-of-type {
          width: 260px;
        }
      }
    }
    .k-header {
      &:first-of-type {
        .k-cell-inner {
          width: 140px;
        }
      }
      &:nth-of-type(2) {
        .k-cell-inner {
          width: 200px;
        }
      }
      &:nth-of-type(3) {
        .k-cell-inner {
          width: 130px;
        }
      }
      &:nth-of-type(4) {
        .k-cell-inner {
          width: 130px;
        }
      }
      &:nth-of-type(5) {
        .k-cell-inner {
          width: 115px;
        }
      }
      &:nth-of-type(6) {
        .k-cell-inner {
          width: 170px;
        }
      }
      &:last-of-type {
        .k-cell-inner {
          width: 260px;
        }
      }
    }
  }
  &--pending {
    table {
      colgroup {
        col {
          &:nth-of-type(6) {
            width: 240px !important;
          }
          &:nth-of-type(7) {
            width: 150px !important;
          }
          &:nth-of-type(8) {
            width: 160px !important;
          }
          &:last-of-type {
            width: 140px !important;
          }
        }
      }
      .k-header {
        &:nth-of-type(6) {
          .k-cell-inner {
            width: 240px !important;
          }
        }
        &:nth-of-type(7) {
          .k-cell-inner {
            width: 150px !important;
          }
        }
        &:nth-of-type(8) {
          .k-cell-inner {
            width: 160px !important;
          }
        }
        &:last-of-type {
          .k-cell-inner {
            width: 140px !important;
          }
        }
      }
    }
    .c-list-orders__name {
      span {
        white-space: nowrap;
      }
    }
    &-small {
      table {
        colgroup {
          col {
            &:nth-of-type(6) {
              width: 160px !important;
            }
            &:last-of-type {
              width: 250px !important;
            }
          }
        }
        .k-header {
          &:nth-of-type(6) {
            .k-cell-inner {
              width: 160px !important;
            }
          }
          &:last-of-type {
            .k-cell-inner {
              width: 250px !important;
            }
          }
        }
      }
      @media screen and (max-width: 767px) {
        table {
          colgroup {
            col {
              &:last-of-type {
                width: 140px !important;
              }
            }
          }
          .k-header {
            &:last-of-type {
              .k-cell-inner {
                width: 140px !important;
              }
            }
          }
        }
        .c-list-orders__actions {
          display: block;
        }
      }
    }
  }
}

.c-orders-listing.has-tag-column {
   table {
     colgroup {
       col {
         &:first-of-type {
           width: 140px;
         }
         &:nth-of-type(2) {
           width: 40px;
         }
         &:nth-of-type(3) {
           width: 200px;
         }
         &:nth-of-type(4) {
           width: 130px;
         }
         &:nth-of-type(5) {
           width: 130px;
         }
         &:nth-of-type(6) {
           width: 115px;
         }
         &:nth-of-type(7) {
           width: 170px;
         }
         &:last-of-type {
           width: 260px;
         }
       }
     }
     .k-header {
       &:first-of-type {
         .k-cell-inner {
           width: 140px;
         }
       }
       &:nth-of-type(3) {
         .k-cell-inner {
           width: 200px;
         }
       }
       &:nth-of-type(4) {
         .k-cell-inner {
           width: 130px;
         }
       }
       &:nth-of-type(5) {
         .k-cell-inner {
           width: 130px;
         }
       }
       &:nth-of-type(6) {
         .k-cell-inner {
           width: 115px;
         }
       }
       &:nth-of-type(7) {
         .k-cell-inner {
           width: 170px;
         }
       }
       &:last-of-type {
         .k-cell-inner {
           width: 260px;
         }
       }
     }
   }
   &--pending {
     table {
       colgroup {
         col {
           &:nth-of-type(7) {
             width: 240px !important;
           }
           &:nth-of-type(8) {
             width: 150px !important;
           }
           &:nth-of-type(9) {
             width: 160px !important;
           }
           &:last-of-type {
             width: 140px !important;
           }
         }
       }
       .k-header {
         &:nth-of-type(7) {
           .k-cell-inner {
             width: 240px !important;
           }
         }
         &:nth-of-type(8) {
           .k-cell-inner {
             width: 150px !important;
           }
         }
         &:nth-of-type(9) {
           .k-cell-inner {
             width: 160px !important;
           }
         }
         &:last-of-type {
           .k-cell-inner {
             width: 140px !important;
           }
         }
       }
     }
     .c-list-orders__name {
       span {
         white-space: nowrap;
       }
     }
     &-small {
       table {
         colgroup {
           col {
             &:nth-of-type(7) {
               width: 160px !important;
             }
             &:last-of-type {
               width: 250px !important;
             }
           }
         }
         .k-header {
           &:nth-of-type(7) {
             .k-cell-inner {
               width: 160px !important;
             }
           }
           &:last-of-type {
             .k-cell-inner {
               width: 250px !important;
             }
           }
         }
       }
       @media screen and (max-width: 767px) {
         table {
           colgroup {
             col {
               &:last-of-type {
                 width: 140px !important;
               }
             }
           }
           .k-header {
             &:last-of-type {
               .k-cell-inner {
                 width: 140px !important;
               }
             }
           }
         }
         .c-list-orders__actions {
           display: block;
         }
       }
     }
   }
 }

.c-orders-listing {
  tr {
    &.k-alt {
      .c-list-orders__actions {
        &.k-grid-content-sticky {
          &::before {
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(252, 250, 249, 0.64) 52%,
              rgba(249, 246, 243, 1) 100%
            );
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .c-orders-listing {
    table {
      colgroup {
        col {
          &:last-of-type {
            width: 140px;
          }
        }
      }
      .k-header {
        &:last-of-type {
          .k-cell-inner {
            width: 140px;
          }
        }
      }
    }
  }
}

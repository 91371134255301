.k-loader-primary {
  color: #610d11;
}
.c-loader {
  &--full-page {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    z-index: 10000;
  }
}
.c-loader--center {
  position: fixed;
  z-index: 99;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &-modal {
    top: calc(50% + 35px);
  }
}
.c-loader--absolute {
  position: absolute;
}

@media screen and (max-width: 767px) {
  .k-loader-lg .k-loader-segment {
    width: 10px;
    height: 10px;
  }
  .k-loader-spinner-3.k-loader-lg .k-loader-canvas {
    width: 32px;
    height: 24px;
  }
}

.c-table-orders {
  position: relative;
  .k-grid-table {
    min-width: 0 !important;
  }

  col {
    &:first-of-type {
      width: 260px;
    }
    &:nth-of-type(2) {
      width: 180px;
    }
    &:nth-of-type(3) {
      width: 90px;
    }
    &:nth-of-type(4) {
      width: 110px;
    }
  }
  &::before {
    // content: "";
    background-color: #f9f6f3;
    position: absolute;
    left: -40px;
    top: -300px;
    right: -40px;
    bottom: -100vh;
    z-index: -1;
  }
  .k-grid-header {
    padding-right: 0;
    thead {
      .k-header {
        padding-left: 0;
        padding-right: 0;
        &:first-of-type {
          .k-cell-inner {
            width: 260px;
          }
        }
        &:nth-of-type(2) {
          padding-left: 0;
          .k-cell-inner {
            width: 180px;
          }
        }
        &:nth-of-type(3) {
          justify-content: flex-end;
          display: flex;
          overflow: visible;
          .k-cell-inner {
            width: 90px;
            padding-right: 20px;
          }
          .k-column-title {
            display: block;
            width: 140px;
            text-align: right;
          }
        }
        &:last-of-type {
          .k-cell-inner {
            width: 110px;
          }
        }
        > .k-cell-inner {
          margin: -8px 0;
        }
      }
      .k-link {
        cursor: default;
        padding: 8px 0;
      }
    }
  }
  .k-grid-container {
    .k-grid-table {
      tr {
        background-color: #fff !important;
      }
      td {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          background-color: #f9f6f3;
          height: 47px;
          z-index: 0;
        }
      }
    }
  }
  .k-grid thead tr {
    display: inherit;
  }
  .k-grid-content-sticky {
    position: sticky !important;
    background-color: #fff !important;
    overflow: visible;
    li {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100% + 7px);
        width: 30px;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.64) 52%,
          rgb(255, 255, 255) 100%
        );
        z-index: 3;
      }
      &:nth-of-type(even) {
        &::before {
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(252, 250, 249, 0.64) 52%,
            rgb(249, 246, 243) 100%
          );
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .c-table-orders {
    thead {
      tr {
        display: table-row;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-subheader {
    &--catalogue {
      position: relative;
      z-index: 1;
      & + .c-tags {
        //background-color: #f9f6f3;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        //position: sticky;
        //top: 100px;
        //height: 100%;
        width: 100%;
        z-index: 9;
        &::before {
          //content: "";
          position: absolute;
          top: -20px;
          bottom: -20px;
          left: 0;
          right: 0;
          background-color: #f9f6f3;
        }
        &.u-sticky {
          background-color: #fff;
          padding-top: 20px;
          padding-bottom: 20px;
          position: sticky;
          top: 80px;
          margin-left: -40px;
          padding-left: 40px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          width: calc(100% + 80px);
          padding-right: 40px;
        }
      }
    }
  }
}

.c-order-advice {
  color: #ed1c24;
  strong {
    margin-right: 4px;
    display: inline-block;
    .k-icon {
      margin-right: 4px;
    }
  }
}

.c-new-order-modal {
  .k-tabstrip-items {
    overflow: visible;

    .k-item {
      font-size: 18px;
      font-weight: 400;
      flex: 1;
      border: 1px solid #2a2a2a;
    }
    .k-link {
      justify-content: center;
    }
  }
  .k-tabstrip > .k-content,
  .k-tabstrip-items-wrapper {
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
  .k-tabstrip > .k-content {
    margin-left: 0;
    margin-right: 0;
  }
  .k-tabstrip-items .k-item.k-active {
    border-color: #610d11;
  }
  &__button {
    width: 100%;
  }
  .c-form-field + .c-form-field {
    margin-top: 16px;
  }
}
.c-day,
.k-calendar-td {
  span {
    font-weight: 700;
  }
  &.k-disabled {
    span {
      font-weight: 400;
    }
  }
}
.c-day--disable {
  outline: none;
  cursor: default;
  opacity: 0.6 !important;
  -webkit-filter: grayscale(0.1);
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
  span {
    font-weight: 400;
  }
}
.k-calendar-title {
  color: #424242 !important;
  padding-left: 0 !important;
  text-transform: none !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  font-size: 14px !important;
}
.k-calendar .k-calendar-cell-inner,
.k-calendar .k-link {
}
.k-calendar .k-nav-today,
.k-calendar .k-calendar-nav-today {
  color: #610d11 !important;
}
.k-calendar .k-calendar-view .k-today {
  color: #610d11;
  border: 1px solid #610d11;

  .k-link {
    color: #610d11 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 0;
  }
  &:hover {
    .k-link {
    }
  }
}
k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
  border-color: #610d11 !important;
  color: #fff !important;
  background-color: #610d11 !important;
}
k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
  color: #fff;
  background-color: #610d11;
}
@media screen and (min-width: 1024px) {
  .c-new-order-modal {
    .k-tabstrip-items {
      .k-item {
        font-size: 18px;
      }
    }
  }
}

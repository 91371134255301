.c-gallery-preview {
  width: 48px;
  height: 48px;
  background-color: transparent;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
  }

  &::after {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z' fill='white'/%3E%3Cpath d='M10.2016 6.60004H9.00156V9.00004H6.60156V10.2H9.00156V12.6H10.2016V10.2H12.6016V9.00004H10.2016V6.60004Z' fill='white'/%3E%3C/svg%3E%0A");
  }
  &:hover {
    &::before {
      background-color: rgba(0, 0, 0, 0.4);
    }

    &::after {
      opacity: 1;
    }
  }
}

.c-modal-gallery {
  .k-dialog {
    width: 90vw !important;
    height: 90vh;
  }
  .k-dialog-titlebar {
    border: none;
    margin-bottom: 0;
  }
  .k-dialog-title {
    display: none;
  }
  .k-scrollview-wrap.k-scrollview-animate {
    transition: transform 0.3s ease-in-out;
  }

  .c-slider-container {
  }
  .c-slider {
    width: 100% !important;
    height: 84vw !important;
    &__slide {
      height: 100%;
    }
    &__nav {
      margin-top: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 8px;
      .k-button {
        padding: 0;
      }
      .k-button-text {
        border: 1px solid #d1a170;
        img {
          display: block;
        }
      }
      .j-active {
        .k-button-text {
          border-color: #000000;
        }
      }
    }
    &__figure {
      background-color: #f5f5f5;
      height: 100%;
      img,
      video {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
      }
    }
  }
}

.c-slider-container--media {
  .c-slider__nav {
    button {
      padding: 0 !important;
      width: 100%;
      aspect-ratio: 1;
      .k-button-text {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          font-family: "object-fit: cover;";
        }
      }
    }
  }
  .c-slider__slide {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .c-modal-gallery {
    .c-slider-container {
      display: flex;
      align-items: flex-start;
      gap: 44px;
    }
    .c-slider {
      width: 65% !important;
      height: 81vh !important;
      &__figure {
        width: 100%;
        height: 81vh;
        object-fit: contain;
      }
    }
    .c-slider__nav {
      width: 35% !important;
    }
  }
}

.c-list-orders__name {
  strong {
    display: block;
    //font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .k-icon {
      vertical-align: top;
    }
  }
}

.c-order-product {
  &__list {
    > *:nth-child(even) {
      background-color: #f9f6f3;
    }
  }
  &__empty {
    font-size: 14px;

    color: #2a2a2a;
    span {
      opacity: 0.5;
    }
  }
  &__selected {
    strong {
      color: #2a2a2a;
      padding: 10px 16px;
      background-color: #f1e9e0;
      border-radius: 40px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1023px) {
  .c-order-product {
    &__empty {
      font-size: 13px;
    }
  }
}

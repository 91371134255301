.c-download-grid__actions {
  .k-button {
    margin: 0 0 0 auto;
  }
}

/*MARKETING*/
.c-download {
  display: block;
  background-color: #fff;
  border: 1px solid #f1e9e0;
  padding: 0;
  text-align: left;
  transition: 0.3s ease;
  &__figure {
    height: 136px;
    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover;";
    }
  }
  &__content {
    padding: 10px 16px;
    height: 58px;
    position: relative;
  }
  &__title {
    font-family: "Arial", sans-serif;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    transition: 0.05s ease;

    .icon {
      margin-right: 8px;
    }
    .icon-pdf {
      color: #e43024;
    }
  }
  &__button {
    margin-top: 0;
    opacity: 0;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    background-color: #f1e9e0;

    .c-download {
      &__title {
        width: calc(100% - 156px);
      }
      &__button {
        opacity: 1;
      }
    }
  }
}
.c-marketing {
  &__title {
    font-family: "Arial", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #2a2a2a;
    display: inline-block;
    background-color: #fff;
    padding-right: 8px;
    position: relative;
    z-index: 2;
  }
  &__list {
    font-size: 14px;
    padding-top: 24px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: -8px;
      height: 1px;
      background-color: #dfcecf;
    }
  }
  &-item {
    //justify-content: space-between;
    flex-wrap: nowrap;
    gap: 16px;
    &__title {
      margin: 0;

      .c-extension {
        display: block;
        width: 24px;
        &::before {
          font-size: 24px;
        }
        //background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.0909 24.1667H26.6364V10.1667L21.9091 10.1667V11.3333H23.0909V13.6667H21.9091V14.8333H23.0909V17.1667H21.9091V18.3333H23.0909V20.6667H21.9091V21.8333H23.0909V24.1667ZM19.5455 24.1667V23H20.7273V21.8333H19.5455V19.5H20.7273V18.3333H19.5455V16H20.7273V14.8333H19.5455V12.5H20.7273V11.3333H19.5455V10.1667L15.9937 10.1666C15.1449 10.1622 14.5921 9.76989 13.9978 9.04263C13.9158 8.94226 13.6551 8.60847 13.6146 8.55802C13.1719 8.00666 12.9087 7.83333 12.4545 7.83333H5.36364V24.1667H19.5455ZM26.6364 7.83333C27.9418 7.83333 29 8.878 29 10.1667V24.1667C29 25.4553 27.9418 26.5 26.6364 26.5H5.36364C4.05824 26.5 3 25.4553 3 24.1667V7.83333C3 6.54467 4.05824 5.5 5.36364 5.5H12.4545C13.7782 5.5 14.6207 6.05469 15.467 7.10866C15.5234 7.17899 15.7756 7.50186 15.8375 7.57762C16.0233 7.80504 16.0612 7.83302 16.0021 7.83333H26.6364Z' fill='%232B49A3'/%3E%3C/svg%3E%0A");
      }
    }
    &__name {
      /*white-space: nowrap;
      width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;*/
    }
    &__info {
      gap: 8px;
      flex: 1;
      & > span {
        white-space: nowrap;
      }
    }
    &__extension {
      text-transform: uppercase;
    }
    &__dimension {
      //width: 45px;
    }
    &__button {
      border-color: #610d11;
      color: #610d11;
      font-size: 14px !important;
      text-decoration: none !important;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
      &:hover {
        background-color: #610d11;
        color: #fff;
      }
    }
    & + .c-marketing-item {
      margin-top: 16px;
    }
  }
  & + .c-marketing {
    margin-top: 32px;
  }
}

@media screen and (max-width: 767px) {
  .c-downloads-view-grid {
    .c-tile {
      & + .c-tile {
        margin-top: 16px;
      }
    }
  }
  .c-marketing-item {
    padding: 16px;
    border: 1px solid #f1e9e0;
    border-radius: 4px;
    &__info {
      margin-top: 12px;
      padding-bottom: 40px;
      position: relative;
      flex-wrap: wrap;
    }
    &__button {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #610d11;
      color: #fff !important;
    }
    &__badges {
      margin-left: -8px;
      //margin-top: 12px;
    }
  }
}
@media screen and (min-width: 768px) {
  .c-marketing-item {
    align-items: center;
    &__title {
      width: 50%;
    }
    &__info {
      justify-content: flex-end;
      & > span {
        //flex: 1;
      }
    }
    &__badges {
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 8px 0;
    }
    &__publish {
      flex: none !important;
    }
  }
}
@media screen and (min-width: 1024px) {

  .c-marketing-item {
    &__title {
      //width: 800px;
      min-width: 360px;
    }
    &__info {
      & > span {
      }
    }
    &__badges {
      width: 150px;
    }
    &__dimension {
      text-align: right;
      flex: none !important;
      width: 70px;
    }
    &__extension {
      width: 40px;
      flex: none !important;
    }
    &__publish {
      //justify-content: center;
    }
  }
}
@media screen and (max-width: 1280px) {
  .c-marketing-item {
    display: block !important;
  }
}
@media screen and (min-width: 1280px) {
  .c-marketing-item {
    &__info {
      gap: 8px;
    }
    &__badges {
      width: 240px;
    }
  }
}
@media screen and (min-width: 1400px) {
  .c-marketing-item {
    &__info {
      gap: 8px;
    }
  }
}
@media screen and (min-width: 1600px) {
  .c-marketing-item {
    &__title {
      width: 900px;
    }
    &__info {
      gap: 40px;
    }
  }
}

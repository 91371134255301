.c-logo {
  display: block;
  svg {
    display: block;
  }
  &--logged {
    svg {
      width: 100px;
      height: auto;
    }
  }
}

@media screen and (max-width: 1023px) {
  .c-logo {
    &--logged {
      svg {
        height: 36px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .c-logo {
    &--logged {
      svg {
        width: 160px;
      }
    }
    &:not(.c-logo--logged) {
      svg {
        height: 70px;
      }
    }
  }
}

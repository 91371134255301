.c-table-orders {
  position: relative;
  &::before {
    //content: "";
    background-color: #f9f6f3;
    position: absolute;
    left: -40px;
    top: -300px;
    right: -40px;
    bottom: -100vh;
    z-index: -1;
  }
  .k-grid-header {
    thead {
      .k-header {
        &:nth-of-type(2) {
          padding-left: 0;
        }
        &:nth-of-type(3) {
          justify-content: flex-end;
        }
        &:last-of-type {
          width: 160px;
        }
      }
      .k-link {
        cursor: default;
      }
    }
  }
  .k-grid-container {
    .k-grid-table {
      tr {
        background-color: #fff !important;
      }
      td {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          background-color: #f9f6f3;
          height: 47px;
          z-index: 0;
        }
      }
    }
  }
}

.c-footer {
  &__title {
    font-weight: 300;
    margin-left: 22px;
  }
  &__actions {
    border-right: 1px solid #929c90;
    margin-right: 16px;
    padding-right: 8px;
  }

  &__button {
    text-transform: none !important;
    color: #2a2a2a !important;
    letter-spacing: 0 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 12px !important;

    .k-button-text {
      display: flex;
      align-items: center;

      span {
        margin-left: 4px;
      }
    }
    .icon,
    .k-icon {
      margin-right: 4px;
      font-size: 16px;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
  &__sale {
    padding-right: 16px;
    margin-right: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    line-height: 20px;
    span,
    strong {
      display: block;
    }
    span {
      max-width: 47px;
      line-height: 13px;
      margin-right: 12px;
    }
    strong {
      font-size: 13px;
      line-height: 1;
    }
  }
  &__order {
    margin-left: 16px;
    background-color: #437147;
    font-weight: 400;
    font-size: 13px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff !important;
    border: 1px solid #437147;
    border-radius: 0;
    padding: 11px 16px;
    box-shadow: none;
    transition: 0.3s ease;
    &:hover {
      background-color: darken(#437147, 10%);
      border-color: darken(#437147, 10%);
    }
  }
}
.c-percentage {
  background-color: #b4d8ac;
  display: block;
  padding: 4px;
  border-radius: 2px;
  color: #2a2a2a;
  margin-left: 6px;
  margin-right: 6px;
  line-height: 1;
  width: fit-content;
}

/*EXPORT ORDER*/
.c-modal-export-order,
.c-modal-export-catalogue {
  .c-loader {
    left: 50%;
    transform: translateX(-50%);
  }
  .k-dialog {
    height: auto;
  }

  .k-dialog-buttongroup {
    justify-content: center;
    margin-top: 56px;
    .k-button {
      width: 100%;
    }
  }
}
.c-modal-export-order {
  .k-dialog-content {
    overflow: visible;
  }
}
.c-export-order-text {
  font-size: 15px;
  span {
    display: inline-block;
    vertical-align: middle;
  }
  .icon {
    color: #1f7144;
    margin-right: 11px;

    &::before {
      font-size: 18px;
    }
  }
}
.c-modal-message {
  color: #ed1c24;
  background-color: #fff2f2;
  padding: 12px 16px;
  padding-bottom: 24px;
  font-size: 15px;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -24px !important;
  line-height: 1.53;
}
/*MODAL IMPORT ORDER*/
.c-modal-import-order {
  .k-dialog {
    height: auto;
  }
  form {
    & > .k-button {
      margin: 0 0 0 auto;
      margin-top: 24px;
    }
  }
  .c-upload__label {
    margin-left: 0;
    flex: 1;
  }
  .k-file-invalid {
    color: #ed1c24;
  }
  .c-modal-import-error {
    margin-bottom: 24px;
    max-height: 300px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #610d11;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #000;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #610d11;
    }
    &::-webkit-scrollbar-track {
      background: #b7acac;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
      background: #b7acac;
    }
    &::-webkit-scrollbar-track:active {
      background: #b7acac;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    & + .k-button {
      margin: 0 auto;
    }
  }
  .c-import-result {
    display: flex;
    align-items: center;
    .k-icon {
      margin-right: 4px;
    }
    &--failed {
      color: #ed1c24;
    }
    &--valid {
      color: #309e3a;
    }
  }
  .c-alert-error {
    max-height: 150px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #610d11;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #000;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #610d11;
    }
    &::-webkit-scrollbar-track {
      background: #b7acac;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
      background: #b7acac;
    }
    &::-webkit-scrollbar-track:active {
      background: #b7acac;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  &__footer {
    margin-top: 86px;
    border-top: 1px solid #d9d9d9;
    padding-top: 24px;
    color: #2a2a2a;

    p {
      font-size: 20px;
      margin-bottom: 16px;
    }
    a {
      font-size: 13px;
      display: flex;
      align-items: center;
      .k-icon {
        margin-right: 4px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .c-footer {
    &__order {
      margin-left: 0;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;

      //margin-top: 8px;
      .k-button-text {
        font-weight: 700;
      }
    }
  }
  .c-modal-import-order {
    .k-upload .k-dropzone {
      display: block;
      .k-dropzone-hint {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .c-footer {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    &__title {
      font-weight: 400;
      font-size: 22px;
    }
    &__actions {
      /*position: absolute;
      top: 8px;
      right: 8px;
      border: none;
      margin-right: 0;
      padding-right: 0;*/
      border-right: none;
      margin-right: 0;
      padding-right: 0;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 11px;
      li {
        flex: 1;
        & + li {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 11px;
            bottom: 11px;
            left: 0;
            width: 1px;
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    &__button {
      width: 100%;
    }

    &__total {
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-footer {
    &__act {
      margin-left: 16px;
    }
  }
  .c-modal-message {
    padding: 12px 32px;
    padding-bottom: 24px;
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: -32px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .c-footer {
    &__content {
      width: 100%;
      justify-content: flex-end;
    }
    &__title {
      display: none;
    }
    &__button {
      &:first-of-type {
        //padding-left: 0 !important;
      }
    }
    &__actions {
      margin-right: 8px;
      padding-right: 0;
    }

    &__sale {
      padding-right: 8px;
      margin-right: 8px;
    }
    &__total {
    }
    &__order {
      margin-left: auto;
      padding-left: 8px !important;
      padding-right: 8px !important;
      .k-button-text {
        font-size: 12px;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1499px) {
  .c-footer__actions--inline {
    gap: 8px;
    padding-right: 16px;
    .c-footer__button {
      width: 24px;
      height: 24px;
      border: 1px solid #000 !important;
      border-radius: 50% !important;
      justify-content: center;
      padding: 0 !important;
      position: relative;
      .k-button-text {
        display: none;
        position: absolute;
        bottom: calc(100% + 20px);
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        padding: 16px;
        &::after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 8px 0 8px;
          border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .k-icon {
        margin-right: 0;
        display: block;
      }
      &:hover {
        .k-button-text {
          display: block;
        }
      }
    }
  }
}

//STILE ANIMAZIONE MENU IN FOOTER
// Custom animation
.slide-footer-menu-enter {
  transform: translateY(8px);
  opacity: 0;
}
.slide-footer-menu-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.slide-footer-menu-exit {
  transform: translateY(0);
  opacity: 1;
}
.slide-footer-menu-exit-active {
  transform: translateY(8px);
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .c-subheader--new-prospect {
    .c-subheader {
      &__buttons {
        margin-top: 16px;
        .k-button {
          width: 100%;
          & + .k-button {
            margin-top: 16px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1399px) {
  .c-subheader--new-prospect {
    .c-prospect-state {
      margin-left: 0 !important;
    }
  }
}

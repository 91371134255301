.c-subheader--pending-orders {
  .c-filter {
    margin-top: 16px;
  }
}

@media screen and (min-width: 768px) {
  .c-subheader--pending-orders {
    .c-form-field--search {
      margin-left: 24px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-subheader--pending-orders {
    .c-filter {
      margin-top: 0;
      margin-left: 24px;
    }
  }
}

// Customer menu style
.c-dropdown-customers {
  z-index: 102 !important;
}
.c-customers-menu {
  z-index: 999;
  .k-button-text {
    line-height: 1;
  }
  &__content {
    background-color: #fff;
    padding: 16px;
  }

  &__menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 440px;
    border-radius: 0;

    // Custom animation
    .slideFilter-enter {
      transform: translateY(-8px);
      opacity: 0;
    }
    .slideFilter-enter-active {
      transform: translateY(0);
      opacity: 1;
    }
    .slideFilter-exit {
      transform: translateY(0);
      opacity: 1;
    }
    .slideFilter-exit-active {
      transform: translateY(-8px);
      opacity: 0;
    }
    .l-tablet-flex {
      margin-top: 16px;
    }
  }
  &__list {
    max-height: 400px;
    overflow: auto;
    padding: 16px;
    border: 1px solid #610d11;
    margin-top: 16px;
    > li {
      color: #838383;
      font-weight: 700;
      font-size: 15px;
      line-height: 1.27;
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid #d4d4d4;
      &:first-of-type {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
      }
    }
  }
  &__sublist {
    padding-left: 16px;
    margin-top: 16px;
    li {
      color: #2a2a2a;
      font-size: 13px;
      line-height: 1.46;
      font-weight: 400;
      & + li {
        margin-top: 8px;
      }
      .k-button {
        margin-left: 16px;
      }
    }
  }
  .c-form-field {
    &--search {
      min-width: 0;
      .k-icon {
        transform: none;
        top: 30px;
      }
    }
  }
  &__footer {
    background-color: #d8e8d4;
    text-align: center;
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -16px;
  }
}

.c-loader--customers-menu {
  left: 50%;
  transform: translateX(-50%);
  margin-top: 16px;
}

@media screen and (min-width: 1024px) {
  .c-customers-menu {
    position: relative;
    background: #712529;
    border: 1px solid #813d41;
    height: 50px;
    display: flex;
    > .k-button {
      padding-right: 36px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 13px;
      .k-i-chevron-down,
      .k-i-chevron-up {
        position: absolute;
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 6px;
      }
    }
  }
}

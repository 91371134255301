.c-not-found {
  background-color: #f9f6f3;
  text-align: center;
  color: #610d11;
  height: calc(100vh - 220px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 20px;
  &__title {
    font-size: 80px;
    color: #610d11;
    font-weight: 300;
    margin-top: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .c-not-found {
    height: calc(100vh - 215px);
    font-size: 18px;

    &__title {
      font-size: 152px;
      margin-top: 64px;
    }
  }
}

.c-list-orders__actions {
  .k-i-play {
    font-size: 12px;

    &::before {
    }
  }
  .k-button {
    height: 32px;
    background-color: #fff;
    font-size: 14px !important;
    letter-spacing: 0 !important;
    .k-i-preview {
      font-size: 13px;
    }
    &:nth-child(2) {
      width: 100%;
      min-width: 150px;
    }
    &:hover {
      background-color: #610d11;
      color: #fff !important;
    }
  }
  &.k-grid-content-sticky {
    overflow: visible;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc(100% - 10px);
      width: 30px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.64) 52%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}
.c-list-orders__id-order {
  & > div {
    display: inline-block;
    margin-right: 8px;
    & + span {
    }
  }
}

.c-orders-listing {
  tr {
    &:hover {
      .c-list-orders__actions {
        &.k-grid-content-sticky {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .c-list-orders__actions {
    .k-button {
      min-width: 100px !important;
      width: 100%;
      & + .k-button {
        margin-top: 8px;
      }
    }
  }
}

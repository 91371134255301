@use "sass:math";

@font-face {
  font-family: "Canela";
  src: url("../../assets/fonts/CanelaFont/Canela-Regular.otf");
  src: local("Canela"),
    url("../../assets/fonts/CanelaFont/Canela-Regular.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Canela";
  src: url("../../assets/fonts/CanelaFont/Canela-Thin.otf");
  src: local("Canela"),
    url("../../assets/fonts/CanelaFont/Canela-Thin.otf") format("truetype");
  font-weight: 300;
}

//COLORS
$primary: #610d11;
$secondary: #f9f6f3;
$secondaryDark: #f1e9e0;
$tertiary: #d8e8d4;
$black: #000;
$white: #fff;

//FONT
$text-font-family: "Arial", sans-serif;
$title-font-family: "Canela", sans-serif;
$special-font-family: "acumin-pro", sans-serif;

//SIZE TEXT
$text-desktop: 18px;
$text-mobile: 16px;
$lead-size1: 20px;
$lead-size2: 13px;

//SIZE TITLE - DESKTOP

$title-desktop-size1: 48px;
$title-desktop-size2: 32px;
$title-desktop-size3: 24px;
$title-desktop-size4: 20px;
$title-desktop-size5: 18px;

//SIZE TITLE - MOBILE
$title-mobile-size1: 40px;
$title-mobile-size2: 24px;
$title-mobile-size3: 20px;
$title-mobile-size4: 18px;
$title-mobile-size5: 18px;

body {
  color: $black;
  font-family: $text-font-family;
  font-size: $text-mobile;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 1.4;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.l-main {
  flex: 1;
}
* {
  box-sizing: border-box;
}
a,
a:hover {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
address {
  font-style: normal;
}
p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
figure {
  margin: 0;
}
img {
  max-width: 100%;
  height: auto;
}
fieldset {
  padding: 0;
  border: none;
  margin: 0;
}
button,
input[type="submit"] {
  border: none;
  cursor: pointer;
}
button,
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@mixin title {
  color: #2c2c2c;
  font-family: $title-font-family;
  font-weight: 400;
  line-height: 1.1;
  margin: 0;
}
strong {
  font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.t-title,
.k-dialog-title,
h1,
h2,
h3,
h4,
h5,
h6 {
  @include title;
}

.k-dialog-title {
  @extend .t-title--2;
}

// CLASSES MOBILE TITLE

.t-title {
  &--1 {
    font-size: $title-mobile-size1;
  }
  &--2 {
    font-size: $title-mobile-size2;
  }
  &--3 {
    font-size: $title-mobile-size3;
  }
  &--4 {
    font-size: $title-mobile-size4;
  }
  &--5 {
    font-size: $title-mobile-size5;
  }
}

.t-lead {
  font-size: $lead-size1;
  font-family: $text-font-family;
  &--small {
    font-size: $lead-size2;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: 700;
    font-family: $special-font-family;
  }
}

@media screen and (min-width: 1024px) {
  body {
    font-size: $text-desktop;
  }

  // CLASSES DESKTOP TITLE
  .t-title {
    &--1 {
      font-size: $title-desktop-size1;
      line-height: 0.9;
    }
    &--2 {
      font-size: $title-desktop-size2;
      line-height: 1;
    }
    &--3 {
      font-size: $title-desktop-size3;
      line-height: 1.2;
    }
    &--4 {
      font-size: $title-desktop-size4;
    }
    &--5 {
      font-size: $title-desktop-size5;
    }
  }
}

.c-page-error {
  background-color: #fff2f2;
  color: #ed1c24;
  height: calc(100vh - 220px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 20px;
  &__content {
    max-width: 530px;
    margin: 0 auto;
  }
  p {
    overflow-wrap: anywhere;
  }
  .icon {
    font-size: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .c-page-error {
    height: calc(100vh - 215px);
    font-size: 18px;
  }
}

.c-user-menu {
  position: relative;

  &__menu {
    position: absolute;
    top: 100%;
    width: 200px;
    right: 0;
    z-index: 999;
    border-radius: 0;

    // Custom animation
    .slide-enter {
      transform: translateY(-8px);
      opacity: 0;
      transition: all 500ms ease-in-out;
    }
    .slide-enter-active {
      transform: translateY(0);
      opacity: 1;
      transition: all 500ms ease-in-out;
    }
    .slide-exit {
      transform: translateY(0);
      opacity: 1;
      transition: all 500ms ease-in-out;
    }
    .slide-exit-active {
      transform: translateY(-8px);
      opacity: 0;
      transition: all 500ms ease-in-out;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    background-color: #f1e9e0;
    padding: 16px;

    &-item {
      color: #610d11 !important;
      font-size: 11px;
      line-height: 1.36;
      letter-spacing: 2px;
      text-transform: uppercase;
      .k-button {
        color: #610d11 !important;
        padding: 0 !important;
        font-size: 11px;
        line-height: 1.36;
        letter-spacing: 2px;
        text-transform: uppercase;
        &:hover {
          text-decoration: none;
          color: #000 !important;
        }
      }
      a {
        &:hover {
          color: #000;
        }
      }
      & + & {
        margin-top: 8px;
      }
    }
  }
}

.c-user-setting {
  &__title {
    .icon {
      margin-right: 12px;
    }
  }

  &__button {
    width: 100%;
    margin-top: 16px;

    .k-icon {
      font-size: 14px;
      margin-top: -2px;
      margin-left: 14px;

      &.k-i-close {
        font-size: 17px;
      }
    }
  }

  &__content {
    padding: 16px;
    border: 1px solid #2a2a2a;
  }

  &__item:not(:last-of-type) {
    padding-bottom: 16px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 32px;
  }

  &__heading {
    font-weight: 300;
    margin-bottom: 12px;
  }

  &__list {
    color: #757575;
    font-size: 14px;

    strong {
      color: #2a2a2a;
      margin-right: 4px;
    }
  }

  &__footer {
    margin-top: 24px;

    .k-button {
      flex-direction: row-reverse;

      .k-button-text {
        font-weight: 700;
      }

      .k-i-logout {
        margin-left: 4px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .c-user-setting {
    &__list {
      li {
        & + li {
          margin-top: 4px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .c-user-setting {
    &__button {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-top: 0;
    }

    &__list {
      gap: 16px;
    }

    .c-form-field {
      & + .c-form-field {
        margin-top: 0;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-user-setting {
    &__content {
      padding: 32px 24px;
    }

    &__item {
      &:not(:last-of-type) {
        margin-bottom: 50px;
      }
    }

    &__footer {
      margin-top: 32px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .c-user-setting {
    &__list {
      gap: 24px 40px;
    }
  }
}

/*TABS*/

.k-tabstrip .k-animation-container {
  display: block;
  border-radius: 0;
}
.k-tabstrip-items-wrapper {
  color: #757575;
  border-color: #2a2a2a;
}

.k-tabstrip-content,
.k-tabstrip > .k-content {
  color: #757575;
  border-color: #2a2a2a;
  border-radius: 0;
  min-height: 100px;
}

.k-tabstrip-items {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-wrap: nowrap;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
    display: none;
  }
  .k-item {
    color: #610d11;
    font-family: "Canela";
    font-size: 18px;
    line-height: 1.2;
    font-weight: 300;
    border-top: 1px solid #2a2a2a;
    border-left: 1px solid #2a2a2a;
    border-right: none;
    border-bottom: none;
    border-radius: 0 !important;
    transition: 0.3s ease;
    margin: 0;

    &:last-of-type {
      border-right: 1px solid #2a2a2a;
    }

    &:hover {
      color: #610d11;
      background-color: #f9f6f3;
      border-color: #2a2a2a;
    }

    &:active,
    &.k-active,
    .k-item.k-selected {
      color: #fff;
      background-color: #610d11;
      border-color: #2a2a2a;
    }
  }
}

@media screen and (max-width: 767px) {
  .k-tabstrip-items-wrapper {
    margin-left: -16px;
    margin-right: -16px;
  }
  .k-tabstrip-items {
    padding-left: 16px;
    padding-right: 16px;
  }
  .k-tabstrip-content,
  .k-tabstrip > .k-content {
    margin-left: -16px;
    margin-right: -16px;
    border: none;
  }
}

@media screen and (min-width: 1024px) {
  .k-tabstrip-content,
  .k-tabstrip > .k-content {
    padding: 24px;
  }

  .k-tabstrip-items {
    .k-item {
      font-size: 24px;
      line-height: 1;
    }

    .k-link {
      padding: 12px 24px;
    }
  }
}

.c-tag-table-order ,
.c-cell-state-prospect {
  span {
    padding-left: 12px;
    padding-top: 10px;
    border-radius: 2px;
    font-weight: 700;
    font-size: 13px;
    line-height: 1;
    display: flex;
    text-align: center;
    color: #610d11;

  }
  .c-type-icon {
    width:  30px !important;
    height: 30px !important;
    background-color: transparent !important;
    background-size: 85%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .c-type-square {
    &__1 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_square-01.svg');
    }
    &__2 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_square-02.svg');
    }
    &__3 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_square-03.svg');
    }
  }
  .c-type-triangle {
    &__1 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle-01.svg');
    }
    &__2 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle-02.svg');
    }
    &__3 {
      background-image: url('/assets/icons/numbered_shapes_icon/DRV_triangle-03.svg');
    }
  }

}

td.c-tag-table-order  {
  span {
    margin-left: -6px;
  }
}

/*SUBHEADER*/
.c-subheader {
  margin-bottom: 28px;

  .k-i-lock {
    margin-right: 4px;
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    &::before {
      font-size: 16px;
      color: #610d11;
    }
    & + .c-subheader__title {
      display: inline-block;
      vertical-align: sub;
    }
  }

  &__link {
    font-size: 13px;
    color: #2a2a2a;
    line-height: 1;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    .k-i-arrow-left {
      margin-right: 8px;
      &::before {
        content: "\e918";
        font-family: "icomoon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: rotate(180deg);
        font-size: 10px;
        color: #2a2a2a;
      }
    }
  }
  &__actions {
    .k-button-text {
      font-weight: 700;
    }
    .k-button {
      .k-icon {
        margin-left: 4px;
      }
    }
  }
  p {
    margin: 16px 0;
    color: #757575;
    font-size: 14px;
    strong {
      color: #2a2a2a;
      font-weight: 700;
    }
  }

  &__text {
    margin-top: 8px;
    p {
      margin: 0;
    }
  }
}
/*GRID TABLE*/
.k-grid-content {
  overflow-y: auto;
}
.k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
  color: #fff;
  background-color: #610d11;
  font-weight: 700;
  font-size: 13px;
}

.k-grid {
  border: none;
  th,
  td {
    border: none;
  }
  th {
    .k-icon {
      color: #fff;
    }
  }
  tr.k-alt {
    background-color: #f9f6f3;
  }
  td {
    color: #2a2a2a;
    font-size: 13px;
    box-shadow: none !important;
    .k-i-lock {
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  td.no-overflow {
    text-overflow: unset !important;
  }

  .k-button-outline {
    text-transform: none !important;
    font-size: 14px !important;
    letter-spacing: 0 !important;
    padding: 7px 12px !important;
    background-color: #fff !important;
    &:hover {
      background-color: #610d11 !important;
    }
    .k-icon {
      margin-left: 8px;
    }
    .k-i-arrow-right {
      &::before {
        font-size: 12px;
      }
    }
  }
}

col.k-sorted,
th.k-sorted {
  background-color: transparent;
}

.k-grid tbody > tr:not(.k-detail-row):hover,
.k-grid tbody > tr:not(.k-detail-row).k-hover {
  //background-color: #f1e9e0;
  background-color: rgba(0, 0, 0, 0.06);
}

.k-pager-numbers {
  .k-link {
    color: #610d11;
    transition: 0.3s ease;
    border-radius: 50%;
    &:hover,
    &.k-hover {
      color: #2a2a2a;
      background-color: transparent;
    }
    &.k-selected {
      color: #fff;
      background-color: #610d11;
    }
  }
}

.k-pager-sizes {
  margin-left: auto;
}
.k-pager-nav {
  .k-icon {
    font-size: 22px;
    &.k-i-caret-alt-to-right,
    &.k-i-caret-alt-to-left {
      font-size: 14px;
    }
  }
  .k-i-caret-alt-right {
    &::before {
      content: "\e014";
    }
  }

  .k-i-caret-alt-left {
    &::before {
      content: "\e016";
    }
  }
}
.k-pager-numbers {
  margin: 0 8px;
}

.k-pager-nav.k-link:focus,
.k-pager-refresh:focus,
.k-focus.k-pager-nav.k-link,
.k-focus.k-pager-refresh {
  box-shadow: none;
}
.k-pager-nav.k-link:hover,
.k-pager-refresh:hover,
.k-hover.k-pager-nav.k-link,
.k-hover.k-pager-refresh {
  background-color: transparent;
}

.k-grid-pager {
  border: none;
  background-color: transparent;
}

.k-grid-table {
  min-width: 950px;
}

.c-table-gragrances {
  .k-grid-pager {
    background-color: #f9f6f3;
    padding-top: 24px;
  }
}

.k-master-row.k-alt .k-grid-content-sticky,
.k-master-row.k-alt .k-grid-row-sticky {
  background-color: #fff;
}
.k-master-row.k-alt:nth-of-type(even) .k-grid-content-sticky,
.k-master-row.k-alt:nth-of-type(even) .k-grid-row-sticky {
  background-color: #f9f6f3;
}
.k-master-row.k-alt:hover .k-grid-content-sticky,
.k-master-row.k-alt:hover .k-grid-row-sticky {
  background-color: #f1e9e0;
}
.k-grid-header th.k-grid-header-sticky,
.k-grid-header td.k-grid-header-sticky,
.k-grid-header .k-grid-header-sticky.k-sorted {
  background-color: #610d11;
  color: #fff;
}

.k-master-row:hover .k-grid-content-sticky,
.k-master-row:hover .k-grid-row-sticky,
.k-master-row.k-hover .k-grid-content-sticky,
.k-master-row.k-hover .k-grid-row-sticky {
  //background-color: #f1e9e0;
  background-color: #f0f0f0;
}
.k-master-row:hover:nth-of-type(even) .k-grid-content-sticky,
.k-master-row:hover:nth-of-type(even) .k-grid-row-sticky,
.k-master-row.k-hover:nth-of-type(even) .k-grid-content-sticky,
.k-master-row.k-hover:nth-of-type(even) .k-grid-row-sticky {
  background-color: #f0f0f0;
}

.k-grid th {
  overflow: visible;
  &:hover {
    span.k-column-title {
      word-break: break-all;
      position: absolute;
      z-index: 1;
      top: 4px;
      overflow: visible;
      background: #f8f6f3;
      padding: 4px;
      display: block;
      color: #2a2a2a;
      border-radius: 3px;
      box-shadow: 3px 3px 3px black;
    }
  }
}

.c-table-customers {
  table {
    colgroup {
      col {
        &:first-of-type {
          width: 110px;
        }
        &:nth-of-type(2) {
          width: 240px;
        }
        &:nth-of-type(3) {
          width: 160px;
        }
        &:nth-of-type(4) {
          width: 160px;
        }
        &:nth-of-type(5) {
          width: 150px;
        }
        &:nth-of-type(6) {
          width: 240px;
        }
        &:last-of-type {
          width: 160px;
        }
      }
    }
    .k-header {
      &:first-of-type {
        .k-cell-inner {
          width: 110px;
        }
      }
      &:nth-of-type(2) {
        .k-cell-inner {
          width: 240px;
        }
      }
      &:nth-of-type(3) {
        .k-cell-inner {
          width: 160px;
        }
      }
      &:nth-of-type(4) {
        .k-cell-inner {
          width: 160px;
        }
      }
      &:nth-of-type(5) {
        .k-cell-inner {
          width: 150px;
        }
      }
      &:nth-of-type(6) {
        .k-cell-inner {
          width: 240px;
        }
      }
      &:last-of-type {
        .k-cell-inner {
          width: 160px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .c-subheader {
    &__actions {
      margin-top: 16px;
      .k-button-md:not(.k-button-link):not(.k-icon-button),
      .k-button-sm:not(.k-button-link):not(.k-icon-button) {
        padding: 8px;
        font-size: 11px;
      }
    }
    &__text {
      p + p {
        margin-top: 8px;
      }
    }
    &--listing-orders {
      .c-subheader__filter {
        gap: 8px;
      }
    }
  }
  .k-pager-sizes {
    font-size: 0;
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .c-subheader {
    &__filter {
      > * + * {
        margin-left: 24px;
      }
    }
  }
  .k-pager-numbers {
    margin: 0 32px;
  }
}

@media screen and (max-width: 1023px) {
  .k-grid {
    th,
    td {
      //width: 150px;
    }
    thead {
      tr {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-subheader {
    .k-i-lock {
      &::before {
        //font-size: 20px;
      }
    }
    .l-desktop-flex {
      p {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 8px;
      }
    }
    &__text {
      p {
        & + p {
          margin-left: 8px;
        }
      }
    }
    &__actions {
      .k-button {
        .k-icon {
          margin-left: 8px;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .c-subheader {
    &__text {
      margin-top: 0;
      margin-left: 8px;
    }
  }
}

.c-cart {
  &--notes {
    .c-cart {
      &__main {
        position: relative;
      }
      &__header {
        background-color: transparent;
      }
      &__footer {
        background-color: #fff;
        &::before {
          content: "";
          position: absolute;
          left: 16px;
          right: 16px;
          top: 0;
          height: 1px;
          background-color: #f1e9e0;
        }
      }
    }
    .c-note-item {
      font-size: 15px;
      .k-icon {
        color: #610d11;
        &.k-i-file-config {
          font-size: 20px;
        }
      }
      &__header {
        border-bottom: 1px solid #d9d6d2;
        padding-bottom: 8px;
        strong {
          color: #610d11;
          font-size: 14px;
        }
      }
      &__date {
        font-weight: 400;
        font-size: 13px;
        color: #545454 !important;
        line-height: 1;
      }
      &__text {
        line-height: 1.53;
        margin-top: 8px;
        /*display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden; */
        strong {
          font-weight: 700;
        }
      }
      &__footer {
        text-align: right;
        margin-top: 8px;
      }
      &__link {
        color: #610d11;
        font-weight: 700;
        font-size: 13px;
        display: inline-block;
        background-color: transparent;
        padding-left: 0;
      }
      &__element {
        font-size: 14px;
        & + .c-note-item__element {
          margin-top: 10px;
        }
        & > strong {
          text-transform: uppercase;
          font-size: 11px;
          display: flex;
          align-items: center;
          gap: 4px;
          margin-bottom: 8px;
          .k-icon {
            color: #000000;
            &::before {
            }
          }
          .k-i-pencil {
            font-size: 13px;
          }
        }
        ul {
          list-style-type: disc;
          padding-left: 20px;
          li {
            & + li {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
}
.c-new-note {
  &__header {
    padding: 16px;
  }
  &__form {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px;
    textarea {
      &::placeholder {
        color: #dadada;
      }
    }
  }
  &__footer {
    position: absolute;
    padding: 8px 16px;
    left: 0;
    bottom: 0;
    right: 0;
    &::before {
      content: "";
      position: absolute;
      left: 16px;
      right: 16px;
      top: 0;
      height: 1px;
      background-color: #f1e9e0;
    }
    .k-button-flat {
      padding-left: 0 !important;
      letter-spacing: 0 !important;
      font-size: 14px !important;
      text-transform: none !important;
      color: #2a2a2a !important;
      .k-icon {
        color: #610d11 !important;
      }
    }
  }
  &-count {
    display: block;
    text-align: right;
    margin-top: 8px;
    font-size: 14px;
    color: #424242c2;
    &.u-error {
      color: #ed1c24;
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-cart {
    &--notes {
      .c-cart {
        &__content,
        &__footer {
          padding-left: 24px;
          padding-right: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .c-cart {
    &--notes {
      .c-cart {
        &__header {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
  .c-list-notes {
    padding-left: 8px;
    padding-right: 8px;
  }
  .c-new-note {
    &__footer {
      .k-button-solid {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-new-note {
    &__header,
    &__form {
      padding-left: 24px;
      padding-right: 24px;
    }
    &__footer {
      padding-left: 24px;
      padding-right: 24px;
      &::before {
        left: 24px;
        right: 24px;
      }
    }
  }
}

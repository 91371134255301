.c-layout--print {
  background-color: #f9f6f3;
  padding-bottom: 32px !important;
}
.c-print {
  background-color: #fff;
  padding: 24px 32px;
  font-size: 13px;
  max-width: 840px;
  margin-inline: auto;
  color: #2a2a2a;
  &__header {
    background-color: #610d11;
  }
  &__heading {
  }

  &__main-title {
    gap: 8px;
    font-size: 16px;
    line-height: 1;
    align-items: baseline;
    color: #000;
    margin-bottom: 16px;
    strong {
      font-size: 26px;
      font-weight: 400;
    }
  }
  &__status {
    font-size: 13px;
    font-family: Arial;
    line-height: 19px;
    border-radius: 2px;
    background: #ffddb4;
    padding-inline: 4px;
    &.s--5 {
      background-color: #ffddb4;
    }
    &.s-12 {
      background-color: #f1e9e0;
    }
    &.s--1 {
      background-color: #ffd3d3;
    }
    &.s-31 {
      background-color: #e5d1ff;
    }
    &.s-14 {
      background-color: #d4e8e6;
    }
    &.s-2 {
      background-color: #d7e1f6;
    }
    &.s-11 {
      background-color: #fff9b4;
    }
    &.s-16 {
      background-color: #d8f6d4;
    }
  }
  &__current-date {
    color: #757575;
    font-size: 12px;
    font-family: Arial;
  }
  &__details {
    gap: 16px;
    li {
      display: inline-flex;
      align-items: baseline;
      span {
        color: #757575;

        .k-icon {
          color: #610d11;
          margin-right: 4px;
          display: inline-block;
          vertical-align: bottom;
          line-height: 0.8;
        }
      }
    }
  }
  &__header {
    padding: 12px 32px;
    background-color: #610d11;
    margin-inline: -32px;
    margin-top: -24px;
    margin-bottom: 24px;
    svg {
      width: 118px;
      height: auto;
      display: block;
    }
  }
  &__main {
    padding-block: 12px;
    margin-block: 12px;
    border-block: 1px solid #f1e9e0;
  }
  &__title {
    font-size: 20px;
    color: #000;
    line-height: 1.5;
    margin-bottom: 8px;
  }
  &__list {
    li {
      display: flex;
      gap: 4px;
      & + li {
        margin-top: 8px;
      }
      strong {
        font-weight: 700;
        color: #2a2a2a;
        white-space: nowrap;
      }
    }
  }
  &__products {
    & + .c-print__products {
      margin-top: 22px;
    }
  }
  &__label {
    border-bottom: 2px solid #f1e9e0;
    margin-bottom: 16px;
    span {
      display: inline-block;
      padding: 6px 12px;
      background-color: #f1e9e0;
      font-family: "Arial", sans-serif;
      font-weight: 700;
      color: #2a2a2a;
    }
  }
  &__item {
    & + .c-print__item {
      margin-top: 24px;
    }
  }
  &__cat-heading {
    margin-bottom: 4px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #610d11;
    }
    strong {
      color: #2a2a2a;
      font-weight: 700;
      font-size: 13px;
      padding-left: 16px;
      background-color: #fff;
      position: relative;
    }
  }
  &__cat-title {
    font-size: 16px;
    color: #610d11;
    padding-right: 16px;
    background-color: #fff;
    position: relative;
  }
  &__list-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    line-height: 19px;
    gap: 16px;
    &:nth-of-type(even) {
      background-color: #f9f6f3;
    }
    .c-badge {
      border-radius: 2px;
      background: #f1e9e0;
      display: block;
      width: fit-content;
      font-size: 11px;
      padding: 3px 12px;
      font-weight: 600;
      line-height: 1.1;
      &--special {
        background-color: #dbdbdb;
      }
    }
  }
  &__numbers {
    gap: 8px;
    color: #2a2a2a;
    justify-content: flex-end;
    min-width: 140px;
  }
  &__discount {
    border-radius: 2px;
    background-color: #dbdbdb;
    display: inline-block;
    font-weight: 600;
    font-size: 11px;
    padding-inline: 4px;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f1e9e0;
    padding: 16px;
    margin-inline: -32px;
    margin-bottom: -24px;
    margin-top: 50px;
    span {
      display: block;
      font-family: "Canela", serif;
      font-size: 20px;
    }
    strong {
      color: #2a2a2a;
      font-weight: 700;
      font-size: 16px;
    }
  }
  &__buttons {
    margin-top: 20px;
  }
}
.c-page-error {
  & + .k-button {
    margin-inline: auto;
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .c-print {
    padding: 24px 16px;
    &__header {
      margin-inline: -16px;
    }
    &__main-title {
      strong {
        display: block;
        margin-bottom: 4px;
      }
    }
    &__status {
      margin-inline: 8px;
    }
    &__details {
      li {
        display: flex;
        & + li {
          margin-top: 4px;
        }
      }
    }
    &__footer {
      margin-inline: -16px;
    }
  }
}
@media screen and (min-width: 768px) {
  .c-print {
    &__list-element {
      .c-badge {
        display: inline-block;
        margin-left: 12px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-layout--print {
    padding-top: 90px !important;
    padding-left: 32px !important;
  }
}

@media print and (color) {
  @page {
    size: auto;
    margin-top: 0;
    margin-inline: 0;
  }

  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  body {
    background-color: #f9f6f3 !important;
  }
  .c-print {
    &__buttons {
      display: none !important;
    }
    &__main-title {
      span,
      small {
        margin-left: 8px !important;
      }
    }
    &__list-element {
      span {
        display: inline-block !important;
        &:first-of-type {
          margin-right: 8px !important;
        }
      }
    }
    &__details {
      li + li {
        margin-left: 16px !important;
      }
    }
  }
}

.c-content-home {
  .c-slider {
    height: 326px !important;
    &-container {
      margin-left: -17px;
      margin-top: -20px;
      margin-right: -17px;
    }
    &__slide {
      position: relative;
      height: 100%;
    }
    &__content {
      position: absolute;
      z-index: 1;
      left: 16px;
      bottom: 16px;
      color: #fff;
    }
    &__figure {
      position: relative;
      height: 100%;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: none;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.64) 34.72%,
          rgba(0, 0, 0, 0) 70.47%
        );
      }
      img {
        width: 100% !important;
        height: 100% !important;
        -o-object-fit: cover;
        object-fit: cover;
        font-family: "object-fit: cover;";
        display: block;
        height: 326px !important;
      }
    }
    &__title {
      color: #fff;
      font-weight: 300;
      white-space: normal;
      font-size: 34px;
      line-height: 1.2;
    }
    &__text {
      color: #e0e0e0;
      font-size: 15px;
      margin-top: 8px;
      margin-bottom: 16px;
    }
    &__button {
      background-color: #fff !important;
      color: #610d11 !important;
      width: -webkit-fit-content !important;
      width: -moz-fit-content !important;
      width: fit-content !important;
    }
    & .k-skeleton:not(&__loader-background) {
      background-color: #dbd7d7 !important;
      display: block !important;
      margin: 0 0 0 auto;
    }
    &-container {
      .c-placeholder {
        height: 326px;
        text-align: center;
        background-color: #f5f5f5;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          max-width: 900px;
        }
      }
    }
  }
  .k-scrollview-wrap.k-scrollview-animate .k-scrollview-view {
    width: auto;
  }
  .k-scrollview-wrap.k-scrollview-animate {
    transition: transform 0.3s ease-in-out;
  }

  .k-scrollview-nav-wrap {
    left: auto;
    right: 16px;
    bottom: 32px;
    height: 12px;
  }
  .k-scrollview-pageable,
  .k-scrollview-nav {
    padding: 0;
    overflow: visible;
  }
  .k-scrollview-pageable > .k-button,
  .k-scrollview-nav > .k-link {
    background: #fff;
    border: #fff;
    margin: 0 4px;
    width: 8px;
    height: 8px;
  }
  .k-scrollview-pageable > .k-button.k-primary,
  .k-scrollview-nav > .k-link.k-primary {
    background: #d1a170;
    border: #d1a170;
  }
}

@media screen and (min-width: 1024px) {
  .c-content-home {
    .c-slider {
      height: 386px !important;
      &-container {
        margin: 0;
        .c-placeholder {
          height: 386px;
        }
      }
      &__content {
        max-width: 560px;
        text-align: right;
        top: 32px;
        right: 72px;
        bottom: auto;
        left: auto;
      }
      &__figure {
        &::after {
          background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0.49) 49.31%,
            rgba(0, 0, 0, 0) 75.95%
          );
          //background: none;
        }
        img {
          height: 386px !important;
        }
      }
      &__title {
        font-size: 60px;
        line-height: 1;
      }
      &__text {
        font-size: 19px;
        margin-top: 12px;
        margin-bottom: 24px;
      }
      &__button {
        margin: 0 0 0 auto;
      }
    }
    .k-scrollview-nav-wrap {
      right: 72px;
      bottom: 28px;
    }
  }
}

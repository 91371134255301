.c-tiles {
  margin-top: 16px;
  &-container {
    margin-top: 40px;
    .c-empty-state {
      height: auto;
      padding: 16px;
      margin-top: 16px;
      &__content {
        top: auto;
        left: auto;
        transform: none;
        max-width: 100%;
      }
    }
  }
}
.c-tile {
  & .k-skeleton-pulse.k-skeleton {
    display: block !important;
    border: none !important;
  }
  &--small {
    border: 1px solid #610d11;
    position: relative;
    padding-bottom: 72px !important;
    padding-top: 16px !important;

    p {
      font-size: 13px !important;
      & + p {
        margin-top: 4px;
      }
    }
    .c-tile {
      &__name {
        font-size: 15px;
        color: #2a2a2a;
        margin-bottom: 8px;
        display: block;
      }
      &__button {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-transform: none !important;
        color: #610d11 !important;
        background-color: #fff !important;
        text-align: right;
        letter-spacing: 0 !important;
        justify-content: flex-end;
        transition: 0.3s ease;
        border: none !important;
        border-top: 1px solid #610d11 !important;
        padding: 8px 16px !important;
        font-size: 13px !important;
        line-height: 1 !important;
        height: 32px;
        &.k-skeleton {
          left: unset;
          right: 16px;
          border: none !important;
        }
        &:hover {
          color: #fff !important;
          background-color: #610d11 !important;
        }
        .k-button-text {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
}
.c-table-list {
  &__item {
    font-size: 13px;
    color: #2a2a2a;
    padding: 16px 32px 16px 16px;
    background-color: #f9f6f3;
    & .k-skeleton-pulse.k-skeleton {
      display: block !important;
      border: none !important;
    }
    &-button {
      background-color: #fff !important;
      text-transform: none !important;
      transition: 0.3s ease;
      display: flex;
      padding: 8px 16px;
      color: #610d11;
      line-height: 1.155;
      &:hover {
        color: #fff !important;
        background-color: #610d11 !important;
      }
    }
  }
}
.c-table-list-container {
  margin-top: 40px;
  &__header {
    margin-bottom: 16px;
  }
  .c-empty-state {
    height: auto;
    padding: 16px;
    margin-top: 16px;
    &__content {
      top: auto;
      left: auto;
      transform: none;
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 1023px) {
  .c-tiles {
    &-container {
      position: relative;
      padding-bottom: 60px;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -16px;
        bottom: 40px;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 53%,
          #fff 100%
        );
        z-index: 2;
        width: 120px;
      }
      &__button {
        position: absolute;
        bottom: 0;
        background-color: #2a2a2a;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff;
        font-size: 13px;
        padding: 10px;
        width: 100%;
        justify-content: center;
        .k-icon {
          margin-left: 14px;
          &::before {
            font-size: 12px;
          }
        }
      }
    }
    overflow-x: auto;
    flex-wrap: nowrap;
    margin-right: -16px;
    padding-right: 70px;
    margin-left: -16px;
    padding-left: 16px;

    .c-tile {
      &--small {
        width: 276px;
        flex: none !important;
        padding-bottom: 52px !important;
      }
    }
  }

  .c-table-list {
    &-container {
      position: relative;
      padding-bottom: 60px;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -16px;
        bottom: 40px;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 53%,
          #fff 100%
        );
        z-index: 2;
        width: 120px;
      }
      &__button {
        position: absolute;
        bottom: 0;
        background-color: #2a2a2a;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff;
        font-size: 13px;
        padding: 10px;
        width: 100%;
        justify-content: center;
        .k-icon {
          margin-left: 14px;
          &::before {
            font-size: 12px;
          }
        }
      }
    }
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-right: 70px;
    margin-left: -16px;
    padding-left: 16px;
    margin-right: -16px;

    &__item {
      font-size: 13px;
      color: rgb(42, 42, 42, 0.7);
      background-color: #f9f6f3;
      border: 1px solid #610d11;
      width: 276px;
      flex: none;
      padding-right: 16px !important;
      position: relative;
      padding-bottom: 32px !important;
      span {
        line-height: 1.2;
        display: block;
        strong {
          color: #2a2a2a;
        }
        & + span {
          margin-top: 8px;
        }
        &:first-of-type {
          font-size: 15px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      &-button {
        font-weight: 400;
        letter-spacing: 0 !important;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border: none !important;
        border-top: 1px solid #610d11 !important;
        justify-content: flex-end;
        &.k-skeleton {
          left: unset;
          right: 16px;
        }
      }
      &-details {
        li {
          &:first-of-type {
            margin-bottom: 12px;
          }
        }
      }
      &-total {
        font-size: 15px;
        position: relative;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-top: 12px !important;
        display: block;
        &::before {
          content: "";
          position: absolute;
          left: -16px;
          right: -16px;
          top: 0;
          height: 1px;
          background-color: #610d11;
        }
      }
      & + .c-table-list__item {
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-tiles {
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #610d11;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #000;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #610d11;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
      background: #b7acac;
    }
    &::-webkit-scrollbar-track:active {
      background: #b7acac;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    .k-animation-container {
      display: block !important;
    }
    .c-tile {
      & .k-skeleton {
        display: block !important;
        border: none !important;
      }
      &__button {
        padding-top: 10px;
        padding-bottom: 10px;
        &.k-skeleton {
          left: unset;
          right: 16px;
        }
      }
    }
  }
  .c-table-list {
    &-container {
      &__button {
        color: #610d11;
        font-size: 15px;
        .k-icon {
          margin-left: 8px;
          margin-bottom: -4px;
          &::before {
            font-size: 10px;
          }
        }
      }
    }
    &__item {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      &-button {
        background-color: #fff !important;
        text-transform: none !important;
        font-weight: 700 !important;
        margin-left: auto;
        height: 32px;
        border: 1px solid #610d11 !important;
        &.k-skeleton-pulse.k-skeleton {
          border: none !important;
          display: flex !important;
          margin-left: auto;
        }
      }
      &:nth-of-type(even) {
        background-color: #fff;
      }
      span:not(.k-skeleton) {
        width: 120px;
        &:first-of-type {
          width: 200px;
        }
        &:nth-of-type(2) {
          width: 155px;
        }
        & + span {
          margin-left: 16px;
        }
      }
      &-details {
        li {
          width: 120px;
          &:first-of-type {
            width: 200px;
          }
          & + li {
            margin-left: 16px;
          }
        }
      }
    }
  }
  .c-tiles-container__button {
    color: #610d11;
    font-size: 15px;
    .k-icon {
      margin-left: 8px;
      margin-bottom: -4px;
      &::before {
        font-size: 10px;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .c-tiles {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1280px) {
  .c-table-list {
    &__item {
      span {
        &:first-of-type:not(.k-skeleton) {
          width: 400px;
        }
      }
      &-details {
        li {
          width: 150px;
          &:first-of-type {
            width: 220px;
          }
          & + li {
            margin-left: 80px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .c-table-list {
    &__item {
      span {
        & + span {
          margin-left: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .c-table-list {
    &__item {
      span {
        & + span {
          margin-left: 80px;
        }
      }
    }
  }
}

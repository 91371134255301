.c-subheader {
  &--download {
    .c-subheader {
      &__button {
        &--no-filter {
          font-weight: 400 !important;
          letter-spacing: 0 !important;
          font-size: 14px !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          text-transform: none !important;
          .k-button-text {
            color: #000 !important;
          }
          .k-icon {
            color: #610d11 !important;
          }
        }
      }
    }
    .c-tags {
      flex-wrap: wrap;
    }
  }
  &__meta {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 6px;
      right: 0;
      height: 1px;
      background-color: #dfcecf;
      z-index: -1;
    }
    strong {
      font-size: 14px;
    }
    &-text {
      background-color: #fff;
      padding-right: 8px;
    }
    &-buttons {
      .k-button {
        color: #808080;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .c-subheader {
    &--download {
      .c-tags {
        margin-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .c-subheader {
    &__meta {
      margin-top: 16px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-subheader {
    &--download {
      .c-tags {
        margin-right: 24px;
      }
    }
  }
}

.c-user-setting {
  &--edit {
    .c-user-setting {
      &__item {
        padding-bottom: 0;
        border: none;
      }
    }
  }
}

.c-download-grid__actions {
  .k-button {
    margin: 0 0 0 auto;
  }
}

.c-download-grid {
  table {
    colgroup {
      col {
        &:first-of-type {
          width: 120px;
        }
        &:nth-of-type(2) {
          width: 220px;
        }
        &:nth-of-type(3) {
          width: 160px;
        }
        &:nth-of-type(4) {
          width: 140px;
        }
        &:nth-of-type(5) {
          width: 140px;
        }
        &:nth-of-type(6) {
          width: 240px;
        }

        &:last-of-type {
          width: 160px;
        }
      }
    }
    .k-header {
      &:first-of-type {
        .k-cell-inner {
          width: 120px;
        }
      }
      &:nth-of-type(2) {
        .k-cell-inner {
          width: 220px;
        }
      }
      &:nth-of-type(3) {
        .k-cell-inner {
          width: 160px;
        }
      }
      &:nth-of-type(4) {
        .k-cell-inner {
          width: 140px;
        }
      }
      &:nth-of-type(5) {
        .k-cell-inner {
          width: 140px;
        }
      }

      &:nth-of-type(6) {
        .k-cell-inner {
          width: 240px;
        }
      }

      &:last-of-type {
        .k-cell-inner {
          width: 160px;
        }
      }
    }
  }
}

/*MARKETING*/
.c-download {
  display: block;
  background-color: #fff;
  border: 1px solid #f1e9e0;
  padding: 0;
  text-align: left;
  transition: 0.3s ease;
  &__figure {
    height: 136px;
    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover;";
    }
  }
  &__content {
    padding: 10px 16px;
    height: 58px;
    position: relative;
  }
  &__title {
    font-family: "Arial", sans-serif;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    transition: 0.05s ease;

    .icon {
      margin-right: 8px;
    }
    .icon-pdf {
      color: #e43024;
    }
  }
  &__button {
    margin-top: 0;
    opacity: 0;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    background-color: #f1e9e0;

    .c-download {
      &__title {
        width: calc(100% - 156px);
      }
      &__button {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .c-downloads-view-grid {
    .c-tile {
      & + .c-tile {
        margin-top: 16px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
}

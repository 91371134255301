.c-good {
  margin-bottom: 32px;
  &-container {
    margin-top: 20px;
    & + section {
    }
  }

  &__lead {
    font-weight: 400;
    font-size: 13px;
    color: #610d11;
    display: block;
    margin-bottom: 16px;
    line-height: 1.46;
    text-transform: uppercase;
  }
  &__title {
    font-weight: 300;
    //text-transform: lowercase;
    &::first-letter {
      //text-transform: uppercase;
    }
  }
  &__text {
    margin-top: 24px;
    font-size: 15px;
    margin-bottom: 0;
  }
  &__footer {
    margin-top: 16px;
  }
  &__price {
    font-size: 13px;
    strong {
      font-weight: 700;
      font-size: 20px;
      margin-left: 6px;
      color: #610d11;
    }
  }

  .c-slider {
    &-container {
      margin-bottom: 32px;
      .k-scrollview-wrap .k-scrollview img,
      .k-scrollview .k-scrollview-wrap img {
        width: 100% !important;
        height: 100% !important;
      }
    }
    width: calc(100vw - 32px) !important;
    height: calc(100vw - 32px) !important;
    border-color: #d1a170;
    &__nav {
      gap: 8px;
      margin-top: 8px;
      .k-button {
        border: 1px solid #d1a170 !important;
        transition: 0.3s ease;
        padding: 0;
        &.j-active,
        &:hover {
          border-color: #000 !important;
        }
      }
      li {
        width: 33.33%;
      }
    }
    .k-scrollview-wrap.k-scrollview-animate {
      transition: transform 0.3s ease-in-out;
    }
  }
  .c-placeholder {
    width: calc(100vw - 32px) !important;
    height: calc(100vw - 32px) !important;
  }
  &-grid {
    .c-cart-item__available-from {
      margin-left: 0;
      margin-top: 4px;
    }
  }

  &-grid {
    .k-grid-header {
      padding-inline-end: 0;
    }
    thead tr {
      display: contents !important;
    }
    tbody tr {
      td:first-of-type {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        height: 48px;
      }
    }
    table {
      min-width: auto !important;
      colgroup {
        col {
          &:first-of-type {
            width: 50px;
          }
          &:nth-of-type(2) {
            width: 150px;
          }
          &:nth-of-type(3) {
            width: 150px;
          }
          &:nth-of-type(4) {
            width: 360px;
          }
          &:nth-of-type(5) {
            width: 120px;
          }
          &:nth-of-type(6) {
            width: 130px;
          }
          &:nth-of-type(7) {
            width: 70px;
          }
          &:nth-of-type(8) {
            width: 70px;
          }
          &:nth-of-type(9) {
            width: 70px;
          }
          &:nth-of-type(10) {
            width: 70px;
          }
          &:nth-of-type(11) {
            width: 100px;
          }
          &:nth-of-type(12) {
            width: 100px;
          }
        }
      }
      .k-header {
        &:first-of-type {
          .k-cell-inner {
            min-width: 160px;
          }
        }
        &:nth-of-type(2) {
          .k-cell-inner {
            min-width: 150px;
          }
        }
        &:nth-of-type(3) {
          .k-cell-inner {
            min-width: 150px;
          }
        }
        &:nth-of-type(4) {
          .k-cell-inner {
            min-width: 360px;
          }
        }
        &:nth-of-type(5) {
          .k-cell-inner {
            min-width: 120px;
          }
        }
        &:nth-of-type(6) {
          .k-cell-inner {
            min-width: 130px;
          }
        }
        &:nth-of-type(7) {
          .k-cell-inner {
            min-width: 70px;
          }
        }
        &:nth-of-type(8) {
          .k-cell-inner {
            min-width: 70px;
          }
        }
        &:nth-of-type(9) {
          .k-cell-inner {
            min-width: 70px;
          }
        }
        &:nth-of-type(10) {
          .k-cell-inner {
            min-width: 70px;
          }
        }
        &:nth-of-type(11) {
          .k-cell-inner {
            min-width: 100px;
          }
        }
        &:nth-of-type(12) {
          .k-cell-inner {
            min-width: 100px;
          }
        }
      }
    }
  }
  &-grid-custom {
    .k-grid-header {
      padding-inline-end: 0;
    }
    thead tr {
      display: contents !important;
    }
    tbody tr {
      td:first-of-type {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        height: 48px;
      }
    }
    table {
      min-width: auto !important;
      colgroup {
        col {
          &:first-of-type {
            width: 50px;
          }
          &:nth-of-type(2) {
            width: 150px;
          }
          &:nth-of-type(3) {
            width: 150px;
          }
          &:nth-of-type(4) {
            width: 360px;
          }
          &:nth-of-type(5) {
            width: 120px;
          }
          &:nth-of-type(6) {
            width: 120px;
          }
          &:nth-of-type(7) {
            width: 120px;
          }
          &:nth-of-type(8) {
            width: 70px;
          }
          &:nth-of-type(9) {
            width: 70px;
          }
          &:nth-of-type(10) {
            width: 70px;
          }
          &:nth-of-type(11) {
            width: 100px;
          }
          &:nth-of-type(12) {
            width: 100px;
          }
          &:nth-of-type(13) {
            width: 90px;
          }
        }
      }
      .k-header {
        &:first-of-type {
          .k-cell-inner {
            min-width: 160px;
          }
        }
        &:nth-of-type(2) {
          .k-cell-inner {
            min-width: 150px;
          }
        }
        &:nth-of-type(3) {
          .k-cell-inner {
            min-width: 150px;
          }
        }
        &:nth-of-type(4) {
          .k-cell-inner {
            min-width: 360px;
          }
        }
        &:nth-of-type(5) {
          .k-cell-inner {
            min-width: 120px;
          }
        }
        &:nth-of-type(6) {
          .k-cell-inner {
            min-width: 120px;
          }
        }
        &:nth-of-type(7) {
          .k-cell-inner {
            min-width: 120px;
          }
        }
        &:nth-of-type(8) {
          .k-cell-inner {
            min-width: 70px;
          }
        }
        &:nth-of-type(9) {
          .k-cell-inner {
            min-width: 70px;
          }
        }
        &:nth-of-type(10) {
          .k-cell-inner {
            min-width: 70px;
          }
        }
        &:nth-of-type(11) {
          .k-cell-inner {
            min-width: 100px;
          }
        }
        &:nth-of-type(12) {
          .k-cell-inner {
            min-width: 100px;
          }
        }
        &:nth-of-type(13) {
          .k-cell-inner {
            min-width: 90px;
          }
        }
      }
    }
  }
}
.c-disabled-message {
  font-size: 13px;
  color: #ed1c24;
  padding: 8px 16px;
  padding-left: 38px;
  background-color: #fff2f2;
  border: 1px solid #ed1c24;
  display: inline-block;
  position: relative;
  width: 100%;
  &::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.5338 14C14.0421 14 14.3634 13.4539 14.1165 13.0095L8.58288 3.04896C8.32888 2.59175 7.67134 2.59175 7.41734 3.04896L1.88368 13.0095C1.63682 13.4539 1.95813 14 2.46645 14H13.5338ZM7.33344 6.66664C7.33344 6.29845 7.63192 5.99997 8.00011 5.99997C8.3683 5.99997 8.66678 6.29845 8.66678 6.66664V9.99997C8.66678 10.3682 8.3683 10.6666 8.00011 10.6666C7.63192 10.6666 7.33344 10.3682 7.33344 9.99997V6.66664ZM7.33344 12C7.33344 11.6318 7.63192 11.3333 8.00011 11.3333C8.3683 11.3333 8.66678 11.6318 8.66678 12C8.66678 12.3682 8.3683 12.6666 8.00011 12.6666C7.63192 12.6666 7.33344 12.3682 7.33344 12Z' fill='%23ED1C24'/%3E%3C/svg%3E%0A");
    position: absolute;
    top: 9px;
    left: 16px;
  }
  .c-subheader__link + & {
    margin-top: 16px;
  }
  .c-subheader__link + .l-tablet-flex + & {
    margin-top: 16px;
  }
  + .c-orders-listing {
    margin-top: 16px;
  }
}
.c-disabled-message-order-detail{
    margin-top: 0px !important;
    margin-bottom: 16px !important;
}

@media screen and (min-width: 768px) {
  .c-good {
    .c-slider {
      &-container {
        margin-bottom: 0;
      }
      width: calc((100vw - 78px) / 2) !important;
      height: calc((100vw - 78px) / 2) !important;
      margin-right: 16px;
      &__nav {
        margin-right: 16px;
      }
    }
    .c-placeholder {
      margin-right: 16px;
      width: calc((100vw - 78px) / 2) !important;
      height: calc((100vw - 78px) / 2) !important;
    }
  }
}

@media screen and (min-width: 1024px) {
  .c-good {
    &-container {
      padding: 24px;
      background-color: #fff;
      margin-bottom: -30px;
      &::before {
        content: "";
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f9f6f3;
        z-index: -1;
      }
      & + section {
        padding-left: 20px;
        padding-right: 20px;
        background-color: #fff;
      }
    }
    margin-bottom: 40px;
    &__text {
      margin-top: 32px;
    }
    &__footer {
      margin-top: 24px;
    }
    .c-slider {
      &-container {
        flex: none !important;
      }
      margin-right: 32px;
      //width: calc((100vw - 342px) / 2) !important;
      //height: calc((100vw - 342px) / 2) !important;
      width: 380px !important;
      height: 380px !important;
      .c-product-detail--open & {
        width: 16vw !important;
        height: 16vw !important;
      }
      &__nav {
        margin-right: 32px;
        width: 380px;
        .c-product-detail--open & {
          width: 16vw;
        }
      }
    }
    .c-placeholder {
      margin-right: 32px;
      width: 380px !important;
      height: 380px !important;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .c-good {
    &-container {
      .k-tabstrip-items .k-link {
        padding: 12px 10px;
      }
    }
  }
}
@media screen and (min-width: 1280px) {
  .c-good {
    .c-slider {
      width: 400px !important;
      height: 400px !important;
      &__nav {
        width: 400px;
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .c-good {
    &-container {
      .k-tabstrip-items .k-item {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 1399px) {
  .c-product-detail--open {
    display: none;
  }
}

.c-order-action {
  width: 190px;
  .k-button {
    min-width: 102px;
  }
  &__modify {
    text-transform: none !important;
    font-size: 14px !important;
  }
  li {
    padding-right: 15px;
    &:nth-of-type(even) {
      background-color: #f9f6f3;
    }
  }
}

@media screen and (max-width: 767px) {
  .c-order-action {
    .k-button {
      font-size: 12px !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .c-order-action {
    .k-button {
      min-width: 110px;
    }
  }
}

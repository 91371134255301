.u-bg-beige {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: #faf6f3;
    z-index: -1;
  }
  .k-dropdownlist {
    //background-color: #faf6f3 !important;
  }
}
.c-table-gragrances {
  .k-grid-header {
    display: none;
  }
  .k-grid-table {
    min-width: 0;
    background-color: #faf6f3;
  }
  .k-grid-content {
    border-left: 1px solid #610d11 !important;
  }
  tbody {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  tr {
    background-color: #fff !important;
    &:first-of-type {
      .c-product {
        border-top: 1px solid #610d11;
      }
    }
    &:nth-of-type(-n + 2) {
      .c-product {
        border-top: 1px solid #610d11;
      }
    }
  }
}

.c-product {
  text-align: center !important;
  border-right: 1px solid #610d11 !important;
  border-bottom: 1px solid #610d11 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px !important;
  position: relative;
  img {
    width: 120px;
    height: 120px;
    display: block;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  &__lead {
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #d1a170;
    font-weight: 400;
    margin-bottom: 16px;
    display: block;
  }
  &__title {
    font-weight: 300;
    letter-spacing: -0.5px;
    color: #610d11;
    margin-bottom: 14px;
    //text-transform: lowercase;
    &::first-letter {
      //text-transform: uppercase;
    }
  }
  &__meta {
    font-size: 10px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #d1a170;
    color: #ffffff;
    transform: rotate(-45deg);
    position: absolute;
    left: -32px;
    top: 26px;
    padding: 6px;
    width: 140px;
    display: block;
  }
  &__text {
    margin-bottom: 14px;
    margin-bottom: 14px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .k-button {
    width: 100%;
    font-weight: 700 !important;
    font-size: 12px !important;
    text-transform: none !important;
    letter-spacing: 1px;
    padding: 8px !important;
    margin-top: auto;
    .k-icon {
      margin-left: 16px;
      font-size: 12px;
    }
  }
}
.c-filter {
  &__container {
    width: auto !important;
  }
}

@media screen and (max-width: 767px) {
  .c-subheader__filter--catalog {
    .c-filter {
      display: inline-block;
      vertical-align: middle;
      & + .c-filter {
        margin-left: 16px;
      }
    }
    .c-subheader__button {
      width: 100%;
      margin-top: 16px;
    }
  }
  .c-product {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
  .c-table-gragrances {
    tbody {
      grid-template-columns: repeat(3, 1fr);
    }
    tr {
      &:nth-of-type(-n + 3) {
        .c-product {
          border-top: 1px solid #610d11;
        }
      }
    }
  }
  .c-product {
    padding: 24px !important;
  }
}

@media screen and (min-width: 1280px) {
  .c-table-gragrances {
    tbody {
      grid-template-columns: repeat(4, 1fr);
    }
    tr {
      &:nth-of-type(-n + 4) {
        .c-product {
          border-top: 1px solid #610d11;
        }
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .c-table-gragrances {
    tbody {
      grid-template-columns: repeat(5, 1fr);
    }
    tr {
      &:nth-of-type(-n + 5) {
        .c-product {
          border-top: 1px solid #610d11;
        }
      }
    }
  }
}
